@import '../../../styles/var';
@import '../../../styles/mixins';
.headingCont{
    position: relative;
    margin-top: 190px;
    @media (max-width: 1024px) {
        margin-top: 88px; 
    }
    .heading{
        font-size: 48px;
        font-weight: $semiBold;
        font-family: 'Rubik-SemiBold';
        color: $primary-color;
        text-align: center;
        @media (max-width: 1024px) {
            font-size: 32px;
        }
    }
    .headingNext{
        @include size(106px, 5px);
        background-color: $primary-color;
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 1024px) {
            top: 60px;
        }
    }
}

.sectionTitle{
    font-size: $fz-20;
    font-weight: $regular;
    font-family: 'Rubik-Regular';
    max-width: 797px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 61px;
}

.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 13px;
    background: rgba(151, 188, 199, 0.2);
    padding: 23px;
    .img{
        @include size(108px);
        border-radius: 50%;
    }
    .name{
        font-size: $fz-26;
        font-weight: $semiBold;
        font-family: 'Rubik-SemiBold';
        color: $black;
        margin: 14px 0px;
    }
    .city{
        font-size: $fz-16;
        font-weight: $regular;
        font-family: 'Rubik-Regular';
    }
    .description{
        margin-top: 14px;
        font-size: $fz-20;
        font-weight: $regular;
        font-family: 'Rubik-Regular';
        text-align: center;
    }
    @media (max-width: 768px) {
        padding: 16px;
    }
}
.array{
    margin-top: 89px;
    display: grid;
    grid-template-columns: repeat(3,calc((100% - 156px) / 3));
    grid-gap: 78px;
    @media (max-width: 1024px) {
        margin-top: 40px;
        grid-template-columns: repeat(3,calc((100% - 120px) / 3));
        grid-gap: 60px;
    }
    @media (max-width: 768px) {
        margin-top: 40px;
        grid-template-columns: repeat(3,calc((100% - 40px) / 3));
        grid-gap: 20px;
    }
}
