@import '../../styles/var';
@import '../../styles/mixins';

.cont{
    @include size(20px, 20px);
    border-radius: 50%;
    fill: $primary-color;
    background: $primary-color;
    position: fixed;
    top: 5%;
    left: 49px;
    filter: drop-shadow(0px 0px 6px rgba(28, 117, 253, 0.30));
    cursor: pointer;
}