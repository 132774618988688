@import '../../../styles/var';
@import '../../../styles/mixins';
.container{
    background: $primary-color;
    color: $white;
    padding: 115px 0px 75px 0px;
    margin-top: 197px;
    .description{
        font-size: $fz-22;
        font-weight: $regular;
        font-family: 'Rubik-Regular';
        width: 427px;
        margin-bottom: 85px;
    }
    .footerHeading{
        font-size: 32px;
        font-weight: $semiBold;
        font-family: 'Rubik-SemiBold';
    }
    img{
        @include size(222px, 70px);
        margin-right: 7px;
        margin-top: 27px;
    }
   
   
}
.linkCont{
    gap:20px;
    .link{
        font-size: $fz-28;
        font-weight: $medium;
        font-family: 'Rubik-Medium';
        color: $white !important;
        text-decoration: none;
        margin-top: 35px;
    }
}
.socials{
    .footerHeading{
        margin-top: 89px;
    }
}
.socialCont{
   
    display: flex;
    gap: 30px;
    @include size(42px);
}