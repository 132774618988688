@import '../../styles/var';
@import '../../styles/mixins';
.recording{
  border-radius: 26px;
  border: 1px solid $primary-color;
  min-width: 143px;
  min-height: 52px;
  width: fit-content;
  // @include size(143px, 52px);
  svg{
    margin-right: 5px;
  }
}
.stopRecording{
  color: $primary-color;
  text-align: center;
  font-family: Inter-Regular;
  font-size: $fz-12;
  font-style: normal;
  font-weight: $regular;
  line-height: 161.104%;
  border: none;

}
.recordingButtonTitle{
  margin-left: 5px;
  font-family: Inter-Regular;
  font-size: $fz-16;
  font-weight: $regular;
  line-height: 25.78px;
  text-align: center;

}
.recordingButton{
  @include size(143px, 52px);
  padding: 9px 18px 9px 9px;
  gap: 0px;
  border-radius: 26px;
  border: 1px solid #006884;
  background: none;
  color: #006884;
  display: flex;
  align-items: center;
}
.playlist{
  display: flex;
  justify-content: start;
  margin-top: 5px;
}