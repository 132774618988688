@import '../../../styles/var';
@import '../../../styles/mixins';
.profilePhotos{
  // position: relative;
  // .editCover{
  //   position: absolute;
  //   bottom: 30px;
  //   right: 30px;
  // }
  .myProfile{
    // color: #FEFEFE;
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
  }

  .imgContainer {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    // margin-bottom: 22px;
    background: $primary-color;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .profilePhoto{
    @include size(120px);
    @include imageSize(cover, center);
    border-radius: 50%;
  }
  // .nameImages{
  //   position: absolute;
  //   bottom: -110px;
  //   padding-right: 80px;
  // }
  .name{
    display: flex;
    align-items: end;
    margin-left: 50px;
  }

  .profilePhotoEdit{
    position: relative;
    height: 120px;
    margin-bottom: 12px;
    svg{
      position: absolute;
      bottom: 0px;
      right: -15px;

      &:hover{
        cursor: pointer;
      }
    }
  }
}
.formsCont{
  // margin-top: 54px;
  .inputs{
    gap: 0px 50px;
    margin-top: 10px;
    label{
      width:46%;
    }
  }
 
}
