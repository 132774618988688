@import '../../styles/var';
@import '../../styles/mixins';

.dialogCont{
    font-family: Inter-Medium;
    padding: 20px;
    border-radius: 24px !important;
    background: #FEFEFE;
    box-shadow: 0px 14px 42px 0px rgba(20, 20, 43, 0.14);
    width: 100%;
    max-width: 500px;
    .heading{
        font-size: $fz-24;
        font-style: normal;
        font-weight: $bold;
        color: $primary-color;
        font-family: Inter-Bold !important;
    }
}