@import "../styles/var";

.dialog-content{
    width: 500px;
    background-color: #fefefe;
    padding: 30px;
    box-shadow: 0 14px 42px 0 rgba(20,20,43,.14);
  }
  .heading{
    font-size: $fz-24 !important;
    color: $primary-color;
  }
  .create-btn{
    background: $button-color !important;
    border-radius: 5px !important;
    color: white !important;
    height: 48px !important;
    padding: 12px 16px !important;
  }