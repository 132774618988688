@import '../../../styles/var';
@import '../../../styles/mixins';
.profilePhotos{
  .myProfile{
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
    @media (max-width: 425px) {
      font-size: $fz-24
    }
  }
  .profilePhoto{
    @include size(120px);
    @include imageSize(cover, center);
    border-radius: 50%;
  }
  .name{
    display: flex;
    align-items: end;
    margin-left: 50px;
  }

  .profilePhotoEdit{
    position: relative;
    height: 120px;
    margin-bottom: 12px;
    svg{
      position: absolute;
      bottom: 0px;
      right: -15px;

      &:hover{
        cursor: pointer;
      }
    }
  }
}
.formsCont{
  // margin-top: 54px;
  .inputs{
    gap: 0px 50px;
    margin-top: 10px;
    label{
      width:45%;
      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }
  @media (max-width: 1024px) {
    margin: auto;
  }
  @media (max-width: 425px) {
    margin-top: 16px;
  }
}
.imageDefault{
  border-radius: 50%;
  background-color: $primary-color;
  padding: 5px;
  margin-right: 8px;
  @include size(30px);
}
.withSelected{
  width: 45%;
  margin-bottom: 24px;
  height: 0;
  max-width: 501px;
  @media (max-width: 1059px) {
    height: fit-content;
  }
  @media (max-width: 1024px) {
    height: 0;
  }
  @media (max-width: 654px) {
    height: fit-content;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
  label{
    width: 100% !important;
    margin-bottom: 5px;
  }
  .selected{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &:first-child{
      margin-top: -16px;
    }
    .title{
      color:  $gray-color;
      font-family: Inter-Regular;
      font-size: $fz-14;
      font-weight: $regular;
      margin-right: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: flex;
      flex: 1
    }
    .image{
      @include size(30px);
      // padding: 5px;
      background: $primary-color;
      margin-right: 8px;
      border-radius: 50%;
      object-fit: contain;
    }
    svg{
      cursor: pointer;
    }
  }
  .selectedAddress{
    margin-top: 10px;
  }
}