// colors
$primary-color: #006884;
$button-color: #053D57;
$primary-light-shade-color: #FBB0B5;
$gray-color: #939FA8;
$text-color: #464646;
$background-color: #FEFCFA;
$title-color: #977575;
$white: #FFFFFF;
$error-color: #EE1D52;
$black: #021620;
$green: #02BC7D;
$yellow: #FFC048;

// font sizes
$fz-10: 10px;
$fz-12: 12px;
$fz-13: 13px;
$fz-16: 16px;
$fz-14: 14px;
$fz-15: 15px;
$fz-18: 18px;
$fz-20: 20px;
$fz-22: 22px;
$fz-23: 23px;
$fz-24: 24px;
$fz-26: 26px;
$fz-28: 28px;
$fz-30: 30px;
$fz-40: 40px;
$fz-42: 42px;
$fz-51: 51px;

// font weights 
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;

%font-m-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: $bold;
}

%font-r-bold {
    font-family: 'Roboto';
    font-weight: $bold;
}