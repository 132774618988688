@import '../../../styles/var';
@import '../../../styles/mixins';
.profilePhotos{
  // position: relative;
  // .editCover{
  //   position: absolute;
  //   bottom: 30px;
  //   right: 30px;
  // }
  .myProfile{
    // color: #FEFEFE;
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
    @media (max-width: 425px) {
      font-size: $fz-24
    }
  }
  .profilePhoto{
    @include size(120px);
    @include imageSize(contain, center);
    border-radius: 50%;
    padding: 30px;
    background: $primary-color;
  }
  // .nameImages{
  //   position: absolute;
  //   bottom: -110px;
  //   padding-right: 80px;
  // }
  .name{
    display: flex;
    align-items: end;
    margin-left: 50px;
  }

  .profilePhotoEdit{
    position: relative;
    height: 120px;
    margin-bottom: 12px;
    .editAvatar{
      position: absolute;
      bottom: 0px;
      right: -7px;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      padding: 8px;
      z-index: 1;
      background-color: $white;
      &:hover{
        cursor: pointer;
      }
      @media (max-width: 425px) {
        @include size(30px);
        right: -1px;
      }
    }
    
    img{
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
  }
}
.formsCont{
  // margin-top: 54px;
  .inputs{
    gap: 0px 50px;
    margin-top: 10px;
    label{
      width:46%;
      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }
  @media (max-width: 1024px) {
    margin: auto;
  }
  @media (max-width: 425px) {
    margin-top: 16px;
  }
}

