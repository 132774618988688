@import '../../styles/var';
@import '../../styles/mixins';

.profession{
  @include size(200px, 140px);
  border: 3px solid $primary-color;
  font-size: $fz-18;
  font-style: normal;
  border-radius: 5px;
  border-radius: 24px;
  font-family: Inter-SemiBold;
  line-height: 36.697px;
  letter-spacing: 0.12px;
  cursor: pointer;
  // @media (max-width: 1440px) {
  //   height: calc( (80vh - 360px) / 2 );
  //   min-height: 120px;
  //   width: 150px;
  // }
  svg{
    @include size(50px);
    @media (max-width: 425px) {
      @include size(48px);
    }
  }
  .title{
    color: $black;
    @media (max-width: 425px) {
      font-size: $fz-16;
    }
  }
  @media (max-width: 425px) {
    @include size(153px, 110px)
  }
  @media (max-width: 370px) {
    @include size(123px, 80px)
  }

  @media (max-width: 310px) {
    @include size(100px, 60px)
  }
}
.profession.active{
  background: $primary-color !important;
  transition: all .2s;
  .title{
  color: white;
  }
}