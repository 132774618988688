@import "../../styles/var";
@import "../../styles/mixins";

.applicationItem {
    font-family: Rubik-Regular;
    font-weight: $regular;
    position: relative;

    svg {
        path {
            fill: $primary-color;
        }
    }

    .messageIcon {
        position: absolute;
        top: 150px;
        right: 15px;
        cursor: pointer;

        svg {
            path {
                fill: $primary-color;
            }
        }
    }

    .approveBtn {
        background: none;
        border: none;
        padding: 0;
        height: fit-content;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #97BCC7;

        &:hover {
            box-shadow: none;
        }

        svg {
            path {
                margin: auto;
            }
        }
    }

    button {
        span {
            margin-left: 0px !important;
        }
    }

    border-radius: 12px;
    border: 2px solid #F2F2F2;
    background: #FFF;

    // padding: 5px;
    .nameImg {
        padding: 10px;
        border-radius: 4px 4px 0px 0px;
        background: #EEF3FF;
        margin-bottom: 10px;
    }
    .commentCont{
        padding: 10px;
    }
    .userComment {
        color: #939FA8;
        font-size: $fz-16;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-top: 5px
    }

    .imgContainer {
        img {
            @include size(32px);
            border-radius: 50%;
        }

    }

    .accountantComment {
        color: #676972;
        font-size: $fz-18;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .image {
        @include size(40px);
        border-radius: 50%;
    }

    .name {
        color: #006884;
        font-family: Inter-Medium;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 13px;
    }
}

.buttonCont{
    margin-top: 20px;
}

.accepted {
    border-radius: 5px;
    background: $green;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: $fz-14;
}

.accept {
    color: $green;
    font-size: $fz-14;
}

.inputs {
    button {
        margin-left: 10px;
        margin-bottom: 28px;
    }

    svg {
        path {
            fill: white;
        }
    }
}

.commentLabel {
    color: $primary-color;
    font-family: Inter-SemiBold;
    font-size: $fz-14;
    font-weight: 600;
}

.userComment {
    .seeMore {
        color: $primary-color;
        font-size: 12px;
        cursor: pointer;
    }

}