@import '../../../styles/var';
@import '../../../styles/mixins';
.profilePhotos{
  .myProfile{
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
  }
  .profilePhoto{
    @include size(120px);
    @include imageSize(cover, center);
    border-radius: 50%;
  }
  .name{
    display: flex;
    align-items: end;
    margin-left: 50px;
  }

}
.formsCont{
  // margin-top: 54px;
  .inputs{
    gap: 0px 50px;
    margin-top: 10px;
    label{
      width:46%;
    }
  }
 
}
.selected__imgcont{
  margin-right: 8px;
  @include size(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $primary-color;
}
.imageDefault{
  object-fit: contain;
  @include size(20px);
}
.withSelected{
  width: 46%;
  margin-bottom: 19px;
  height: 0;
  @media (max-width: 654px) {
    height: fit-content;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
  label{
    width: 100% !important;
  }
  .selected{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    max-width: 501px;
    &:first-child{
      margin-top: -16px;
    }
    .title{
      color:  $gray-color;
      font-family: Inter-Regular;
      font-size: $fz-14;
      font-weight: $regular;
      margin-right: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .image{
      margin-right: 8px;
      @include size(30px);
      border-radius: 50%;
      object-fit: cover;
    }
    svg{
      cursor: pointer;
    }
  }
  .selectedAddress{
    margin-top: 10px;
  }
  
}

.changePhotoCont{
  cursor: pointer;
  width: 39px;
  height: 39px;
  border-radius: 100%;
  background: $white;
  position: absolute;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0;
}

.imgContainer {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: $primary-color;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}