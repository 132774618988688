@import '../../../styles/var';
@import '../../../styles/mixins';

.conainer {
    background-image: url('../../../../public/landigbackground1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 900px;
    padding-top: 101px;
    position: relative;

    @media (max-width: 1024px) {
        height: 726px;
    }
    @media (max-width: 768px) {
        height: 526px;
    }
    .ellipse {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        height: 600px;

        @media (max-width: 1024px) {
            height: 490px;
        }

        @media (max-width: 768px) {
            display: none;
          }
    }
}

.cnt {
    position: relative;
}

.textImages {
    
    .heading {
        font-size: 48px;
        font-weight: $bold;
        color: $primary-color;
        width: 562px;

        @media (max-width: 1024px) {
            width: 455px;
            font-size: 32px;
        }
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .decription {
        font-size: 22px;
        font-weight: $regular;
        margin-top: 34px;
        margin-bottom: 41px;
        width: 482px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        font-size: 32px;
    }
}

.imagesCont {
    @media (max-width: 768px) {
        display: none;
    }
    .image1 {
        position: absolute;
        right: 223px;
        top: 0;

        @media (max-width: 1024px) {
            width: 200px;
        }
    }

    .image2 {
        position: absolute;
        right: 0;
        top: -44px;

        @media (max-width: 1024px) {
            width: 267px;
        }
    }

}