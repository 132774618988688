@import '../../../styles/var';
@import '../../../styles/mixins';
.resetPassword{
  button{
    width: 100%;
  }
}
.profilePhotos{
  .myProfile{
    // color: #FEFEFE;
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
  }
  .profilePhoto{
    @include size(120px);
    @include imageSize(cover, center);
    border-radius: 50%;
  }
  // .nameImages{
  //   position: absolute;
  //   bottom: -110px;
  //   padding-right: 80px;
  // }
  .name{
    display: flex;
    align-items: end;
    margin-left: 50px;
  }

  

  .profilePhotoEdit{
    position: relative;
    height: 120px;
    margin-bottom: 12px;
    svg{
      @include size(39px);
      position: absolute;
      bottom: 0px;
      right: -15px;

      &:hover{
        cursor: pointer;
      }
    }
    @media screen and (max-width: 480px){
      margin-bottom: 20px;
    }
  }
}

.formsCont{
  // margin-top: 54px;
  .inputs{
    gap: 0px 50px;
    margin-top: 10px;
    label{
      width:46%;
      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }
}

.heading{
  @media screen and (max-width: 480px){
    flex-direction: column !important;
  }
}

.userEmail{
  margin-top: 20px;
  @media screen and (max-width: 480px){
    text-align: center !important;
  }
}