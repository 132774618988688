@import '../../styles/var';
@import '../../styles/mixins';
.searchCont{
  // @include size(91%, 72px);
  height: 33px;
  border: 1px solid #97BCC7;
  border-radius: 8px;
  font-size: $fz-16;
  display: flex;
  max-width: 100%;
  min-width: 400px;
  padding: 8px;
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;
  @media (max-width: 1024px) {
    font-size: $fz-24;
    @include size(87%, 42px);
  }
  svg{
    @include size(24px);
  }
}
.searchInput{
 display: flex;
 flex: 1;
 height: 100%;
 border: none;
 background: none;
 outline: none;
}