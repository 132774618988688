@import '../../../styles/var';
@import '../../../styles/mixins';
.resetPassword{
  button{
    width: 100%;
  }
}
.userProfileButton{
  @media (max-width: 425px) {
    width: 100%;
  }
}
.user_name{
  margin-top: 20px;
  font-family: Rubik-Medium;
  font-weight: $medium;
  font-size: $fz-18;
}
.profilePhotos{
  .myProfile{
    // color: #FEFEFE;
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
  }
  .profilePhoto{
    @include size(120px);
    @include imageSize(cover, center);
    border-radius: 50%;
  }
  // .nameImages{
  //   position: absolute;
  //   bottom: -110px;
  //   padding-right: 80px;
  // }
  .name{
    display: flex;
    align-items: end;
    margin-left: 50px;
  }

}
.formsCont{
  // margin-top: 54px;
  .inputs{
    gap: 0px 50px;
    margin-top: 10px;
    label{
      width:46%;
      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 480px){
    margin-top: 30px;
  }
}

.imgContainer{
  @include size(120px);
  border-radius: 50%;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    @include size(70px)
  }
}
