@import '../../../styles/var';
@import '../../../styles/mixins';

.container{
    background: rgba(151, 188, 199, 0.2);
    padding: 102px 65px;
    display: flex;
    align-items: center;
    margin-top: 196px;
    @media (max-width: 1024px) {
        margin-top: 88px;
        padding: 49px 32px;
      }
    .appImage{
        @include size(650px, 527px);
        margin-right: 82px;
        @media (max-width: 1024px) {
            width: 370px;
            height: 280px;
            margin-right: 50px;
        }
    }
    .heading{
        font-size: 40px;
        font-weight: $bold;
        font-family: 'Rubik-Bold';
        color: $primary-color;
        @media (max-width: 1024px) {
            font-size: 20px;
        }
    }
    .title{
        margin-top: 36px;
        font-size: 20px;
        font-weight: $regular;
        font-family: 'Rubik-Regular';
        @media (max-width: 1024px) {
            margin-top: 20px;
            font-size: 15px;
        }
    }
}