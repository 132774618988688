@import '../../styles/var';
@import '../../styles/mixins';

.button{
  border-radius: 5px;
  background: $primary-color;
  color: white;
  border: none;
  padding: 12px 48px;
  white-space: nowrap;
  height: fit-content;
  gap: 8px;
  @media (max-width: 425px) {
    width: 100%;
  }
  .title{
    font-family: Inter-Medium;
    font-weight: $medium;
    // margin-left: 8px;
    font-size: $fz-14;
    line-height: 22px;
    letter-spacing: 0.07px;
  }
  svg{
    path{
      fill: $white;
    }
  }
}