@import '../../../styles/var';
@import '../../../styles/mixins';

.profilePhotos {
  height: 250px;
  width: 100%;
  background-image: url('../../../../public/cover.png');
  padding: 54px 44px;
  padding-left: 0px;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  left: -30px;
  top: -33px;
  width: calc(100% + 44px);

  @media (max-width: 425px) {
    height: 206px;
  }

  .editCover {
    position: absolute;
    z-index: 1000;
    background-color: $white;
    border-radius: 50%;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    cursor: pointer;

    svg {
      @include size(30px);
    }
  }

  .myProfile {
    color: $primary-color;
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
    margin-bottom: 43px
  }

  .nameImages {
    position: absolute;
    top: 75%;
    padding-right: 80px;
    padding-left: 24px;

    @media (max-width: 1440px) {
      padding-right: 24px;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      padding: 0px;
      // padding-right: 30px;
    }

    // @media (max-width: 425px) {
    //   padding-right: 0px;
    //   left: 0;
    //   bottom: -132px;
    // }
  }

  .name,
  .buttonsCont {
    display: flex;
    align-items: end;
  }

  .name {
    margin-left: 24px;

    @media (max-width: 425px) {
      margin-left: 0px;
    }
  }

  .buttonsCont {

    // @media (max-width: 1270px) {
    //  margin-top: 30px;
    // }
    @media (max-width: 430px) {
      flex-direction: column;
    }

    @media (max-width: 425px) {
      margin-left: 15px;
      margin-top: 10px;
      width: 90%;
    }

    button {
      @media (max-width: 425px) {
        width: 100%;
        // margin-bottom: 20px
      }
    }

    // width: 325px !important;
  }

}

.formsCont {
  margin-top: 60px;

  @media (max-width: 1350px) {
    margin-top: 100px
  }

  @media (max-width: 600px) {
    margin-top: 135px
  }

  @media (max-width: 430px) {
    margin-top: 180px
  }

  .inputs {
    gap: 0px 50px;
    margin-top: 10px;

    @media (max-width: 468px) {
      margin-top: 235px;
    }

    label {
      width: 46%;

      @media (max-width: 1515px) {
        width: 100%;
      }
    }
  }
}

// .profilePhotoEdit{
//   position: relative;
//   svg{
//     position: absolute;
//     bottom: 0px;
//     right: -15px;

//     &:hover{
//       cursor: pointer;
//     }
//   }
// }

.profilePhoto {
  @include size(120px);
  @include imageSize(cover, center);
  border-radius: 50%;
}

.imgContainer {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: $primary-color;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.changePhotoCont {
  cursor: pointer;
  width: 39px;
  height: 39px;
  border-radius: 100%;
  background: $white;
  position: absolute;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0;

  &:hover {
    cursor: pointer;
  }
}