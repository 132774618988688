@import '../../../styles/var';
@import '../../../styles/mixins';
.pageHeading{
  color: $black;
  font-family: Rubik-Medium;
  font-size: $fz-42;
  font-style: normal;
  font-weight: $medium;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: 1510px) {
    font-size: $fz-30;
  }
  @media (max-width: 1024px) {
    font-size: $fz-24;
  }
}
.searchNotification{
  margin-bottom: 25px;
  gap: 20px;
  @media (max-width: 1024px) {
    display: none !important;
  }
}
.employeesArray{
  // gap: 23px;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3,calc((100% - 46px) / 3));
  grid-gap: 23px;
  @media (max-width: 1330px) {
    grid-template-columns: repeat(2,calc((100% - 23px) / 2));
  }
}