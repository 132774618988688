@import '../../styles/var';
@import '../../styles/mixins';

.cont{
  margin-bottom: 20px;
  position: relative;
 
  svg{
    fill: $gray-color;
    margin-right: 3px;
   
  }
  @media (max-width: 767px) {
    width: 100%;
  }

  .hideText{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray-color;
  }
  .title{
    color: $black;
    font-size: $fz-16;
    font-family: Inter-Regular;
    margin-bottom: 4px;
    margin-right: 5px;
    font-weight: 400
  }
  .input{
    @include size(100%, 46px);
    max-width: 501px;
    border-radius: 12px;
    border: 2px solid #97BCC7;
    padding: 0px 8px;
    background: white;
    font-size: $fz-12;
    font-family: Inter-Regular;
    font-weight: 400
  }
}