@import '../../styles/var';
@import '../../styles/mixins';
.notificationWithRound{
    position: relative;
    .round{
        @include size(15px);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $error-color;
        position: absolute;
        top: -7px;
        right: -9px;
        color: $white;
    }
    svg{
        width: 22px;
        height: 22px;
       cursor: pointer;
    }
}