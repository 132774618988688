@import '../../styles/var';
@import '../../styles/mixins';

.menuLeft {
  width: 100%;
  padding-top: 100px;
  background: rgba(25, 59, 103, 0.04);
  height: 100%;
  position: relative;

}

.createdAt {
  font-size: $fz-14;
}

.main {
  padding: 33px 30px;
  display: flex;
  width: calc(100% - 300px);

  // margin-top: 33px;
  @media (max-width: 1200px) {
    padding: 33px 10px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 55px 15px;
  }
}

.mainWithActivityCont {
  width: calc(100% - 500px);

  @media (max-width: 1200px) {
    padding: 33px 10px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 55px 15px;
  }
}

.textarea {
  width: 80%;
  min-height: 31px !important;
}

.notification {
  width: 300px;
  padding: 20px;
  background: rgba(25, 59, 103, 0.04);
  position: fixed;
  right: 0;
  height: 100vh;

  @media (max-width: 1024px) {
    display: none;
  }
}

.activityCont {
  width: 500px;
}

.activity {
  color: #11132A;
  font-family: Rubik-Medium;
  font-size: $fz-22;
  font-style: normal;
  font-weight: $medium;
  line-height: 125.303%;
  margin-bottom: 25px;
}

.notificationIcon {
  @include size(25px);
  cursor: pointer;

  svg {
    @include size(100%);
  }
}

.seeMore {
  cursor: pointer;
  color: $primary-color;
  font-size: $fz-12;
  margin: auto;
}

.notifications {
  max-height: 75vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    overflow: unset;
  }
}

.myInfo {
  margin-bottom: 34px;

  .myAvatarDefault {
    @include size(60px);
    @include imageSize(contain, center);
    background-color: $primary-color;
    border-radius: 50%;
    margin-right: 5px;
    padding: 10px
  }

  .myAvatar {
    @include size(60px);
    @include imageSize(cover, center);
    border-radius: 50%;
    margin-right: 5px;
  }

  .nameProfession {
    display: flex;
    flex: 1;
    font-size: $fz-14;
    font-weight: 600;

    .name {}

    .accountant {}
  }
}

.activityArray {
  max-height: 65vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;

  // margin-right: 15px;
  textarea {}
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(28, 46, 69, 0.1);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

.textareaButton {
  button {
    width: fit-content !important;
  }
}