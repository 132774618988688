@mixin imageSize($size, $pozition) {
  object-fit: $size;
  object-position: $pozition;
}

@mixin backgroundSize($size, $pozition, $repeat) { 
  background-size: $size;
  background-repeat: $repeat;
  background-position: $pozition;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

