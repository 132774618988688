@import '../../../styles/var';
@import '../../../styles/mixins';

.headingCont {
    position: relative;

    .heading {
        font-size: 48px;
        font-weight: $semiBold;
        font-family: 'Rubik-SemiBold';
        color: $primary-color;
        text-align: center;

        @media (max-width: 1024px) {
            font-size: 32px;
        }
    }

    .headingNext {
        @include size(106px, 5px);
        background-color: $primary-color;
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 1024px) {
            top: 60px;
        }
    }
}

.sectionTitle {
    font-size: $fz-20;
    font-weight: $regular;
    font-family: 'Rubik-Regular';
    max-width: 797px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 61px;
}

.features {
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 156px) / 3));
    grid-gap: 78px;
    gap: 78px;
    width: 100%;
    margin-top: 81px;

    @media (max-width: 1024px) {
        margin-top: 60px;
        grid-template-columns: repeat(3, calc((100% - 80px) / 3));
        grid-gap: 40px;
    }

    @media (max-width: 768px) {
        margin-top: 70px;
        grid-template-columns: repeat(3, calc((100% - 40px) / 3));
        grid-gap: 20px;
    }
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 13px;
    background: rgba(151, 188, 199, 0.2);
    padding: 30px;
    text-align: center;
    @media (max-width: 768px) {
        padding: 16px;
    }
    .icon {
        svg {
            path {
                fill: $primary-color;
            }
        }
    }

    .title {
        font-size: $fz-24;
        font-weight: $semiBold;
        font-family: 'Rubik-SemiBold';
        color: $primary-color;
        margin: 24px 0px;
    }

    .description {
        font-size: $fz-16;
        font-weight: $regular;
        font-family: 'Rubik-Regular';
        text-align: center;
    }
}