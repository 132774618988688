@import '../../styles/var';
@import '../../styles/mixins';
.cont{
  margin-bottom: 24px;
  position: relative;
  svg{
    fill: $gray-color;
    margin-right: 3px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  .title{
    color: $black;
    font-size: $fz-16;
    font-family: Inter-Regular;
    margin-bottom: 4px;
    margin-right: 5px;
  }

}
.selectCont{
  border-radius: 12px;
  border: 2px solid #97BCC7;
  @include size(100%, 46px);
    max-width: 501px;
  input{
    display: flex;
    flex: 1;
    height: 30px;
    border: none;
    outline: none !important;
    padding-left: 5px;
    border-radius: 12px;
  }
  .line{
    width: 2px;
    height: 30px;
    background: #97BCC7;
  }
}
.bankImgNotAvailable{
  @include size(36px, 24px);
  margin: 0px 16px;
}
.bankImg{
  @include size(36px, 24px);
  border: 2px solid #97BCC7;
  margin: 0px 16px;
  object-fit: cover;
}
.bankScoreInput{
  font-size: $fz-12;
  font-family: Inter-Regular;
  font-weight: $regular;
}
.bankScoreInputNotAvailable{
  margin-left: 15px;
  color: rgba(25, 39, 57, 0.94);
  font-family: Inter-Regular;
  font-size: $fz-16;
  font-style: normal;
  font-weight: $regular;
  // font-size: $fz-16;
}