@import "../../styles/var";
@import "../../styles/mixins";
.fileDownload{
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $black;
  .title{
    font-size: $fz-12;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .close{
    font-size: $fz-16;
    font-weight: 600;
    color: $error-color;
    cursor: pointer;
  }
  svg{
    width: 30px;
    fill: #02BC7D;
    padding: 0px 5px;
    margin-bottom: 0px !important;
  }
  .icon{
    @include size(15px);
    margin-right: 5px;
  }
}