@import '../../../styles/var';
@import '../../../styles/mixins';
.resetPassword{
  button{
    width: 100%;
  }
}
.email{
  font-family:Inter-SemiBold;
  font-size: $fz-16;
}
.formsCont{
  // margin-top: 54px;
  .inputs{
    gap: 0px 50px;
    margin-top: 10px;
    label{
      width:46%;
      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }
 
}
