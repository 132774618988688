@import "../../styles/var";
@import "../../styles/mixins";
.employeeItem {
  background: var(--gray-050, #f7f8f9);
  cursor: pointer;
  text-decoration: none;
  border-radius: 6px;
  padding: 20px 10px 0 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  width: 100%;

  .imgContainer {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 22px;
    background: $primary-color;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nameProfession {
    padding: 0 0 20px 0;
    .name {
      color: #071440;
      font-family: Inter-SemiBold;
      font-size: $fz-20;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .profession {
      color: #676972;
      font-family: Inter-Regular;
      font-size: $fz-18;
      font-style: normal;
      font-weight: $regular;
      line-height: 18px;
    }
  }
  .image {
    width: 90px;
    height: 90px;
    margin-bottom: 22px;
    border-radius: 100%;
    object-fit: cover;
    text-align: center;
    // @media (max-width: 1480px) {
    //   height: 275px;
    // }
    // @media (max-width: 425px) {
    //   height: 190px;
    // }
  }
}
