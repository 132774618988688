@import '../../../styles/var';
@import '../../../styles/mixins';
.container{
    background: $primary-color;
    color: $white;
    padding: 115px 0px 75px 0px;
    @media (max-width: 1024px) {
        margin-top: 88px;
        padding: 50px 0px;
      }
}
.sectionItems{
    display: flex;
    align-items: center;
    gap: 34px;
    margin-top: 89px;
    @media (max-width: 1024px) {
        gap: 0px;
    margin-top: 30px;
    }
}
.headingCont{
    position: relative;
    .heading{
        font-size: 48px;
        font-weight: $semiBold;
        font-family: 'Rubik-SemiBold';
        text-align: center;
        @media (max-width: 1024px) {
            font-size: 32px;
        }
    }
    .headingNext{
        @include size(106px, 5px);
        position: absolute;
        top: 63px;
        left: 50%;
        transform: translateX(-50%);
        background: $white;
        @media (max-width: 1024px) {
            top: 60px;
        }
    }
}
.sectionTitle{
    font-size: $fz-20;
    font-weight: $regular;
    font-family: 'Rubik-Regular';
    // width: 797px;
    text-align: center;
    margin: auto;
    margin-top: 61px;
    @media (max-width: 1024px) {
        margin-top: 61px;
    }
}
.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 347px;
    border-radius: 13px;
    padding: 30px;
    text-align: center;
    @media (max-width: 768px) {
        padding: 16px;
    }
    .icon{
        svg{
            path{
                fill: $white;
            }
        }
    }
    .title{
        font-size: $fz-24;
        font-weight: $semiBold;
        font-family: 'Rubik-SemiBold';
        margin: 24px 0px;
        @media (max-width: 768px) {
            font-size: $fz-16;
            margin: 12px 0px;
        }
    }
    .description{
        font-size: $fz-16;
        font-weight: $regular;
        font-family: 'Rubik-Regular';
        text-align: center;
        @media (max-width: 768px) {
            font-size: $fz-14;
        }
    }
}