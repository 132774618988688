@import '../../styles/var';
@import '../../styles/mixins';
.cont{
  margin-bottom: 24px;
  position: relative;
  svg{
    fill: $gray-color;
    margin-right: 3px;
   
  }
  @media (max-width: 767px) {
    width: 100%;
  }

  .hideText{
    // width: 107px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray-color;
  }
  .title{
    color: $black;
    font-size: $fz-14;
    font-family: Inter-Regular;
    margin-bottom: 4px;
    margin-right: 5px;

  }
  input{
    @include size(100%, 46px);
    max-width: 501px;
    border-radius: 12px;
    border: 2px solid #97BCC7;
    padding: 0px 8px;
  }
}
.custom_picker{
  // position: relative;
  // z-index: 1;
  margin-bottom: 0px;
//   @media (max-width: 425px) {
//     margin-bottom: 10px;
// }
.close{
  color: $primary-color;
  font-size:20px;
  position: absolute;
  top: 48px;
  right: -132px;
  z-index: 100000000;
  font-weight: $bold;
  cursor: pointer;
  @media (max-width: 1200px) {
    right: 6px;
}
@media (max-width: 425px) {
  left: 290px;
}
}
 
  .day_picker_cont{
    box-shadow: 0px 3px 8px rgba(40, 26, 27, 0.1);
    border-radius: 10px;
    background: $white;
    position: absolute;
    top: 100%;
    z-index: 3;
    @media (max-width: 1200px) {
      right: 0;
  }
  @media (max-width: 425px) {
    left: 0;
    right: auto;
}
  }
}
.custom_picker_layer{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}