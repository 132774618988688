@import '../../styles/var';
@import '../../styles/mixins';
.label{
  color: $black;
  font-family: Inter-Regular;
  font-size: $fz-14;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
  font-weight: $regular;
  line-height: normal;
  margin-bottom: 4px;
}