@import '../../../styles/var';
@import '../../../styles/mixins';
.headingCont{
    position: relative;
    margin-top: 190px;
    @media (max-width: 1024px) {
      margin-top: 88px; 
  }
    .heading{
        font-size: 48px;
        font-weight: $semiBold;
        font-family: 'Rubik-SemiBold';
        color: $primary-color;
        text-align: center;
        @media (max-width: 1024px) {
          font-size: 32px;
      }
    }
    .headingNext{
        @include size(106px, 5px);
        background-color: $primary-color;
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 1024px) {
          top: 60px;
      }
    }
}

.sectionTitle{
    font-size: $fz-20;
    font-weight: $regular;
    font-family: 'Rubik-Regular';
    max-width: 797px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 61px;
}
.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $fz-24;
    
  .textField{
    @include size(625px, 70px);
    border-radius: 7px;
    border: none;
    border-bottom: 2px solid gray;
    font-weight: $regular;
    font-family: 'Rubik-Regular';
    text-align: center;
    margin: 51px 0px;
  } 
  .button{
    font-weight: $medium;
    font-family: 'Rubik-Medium';
    @include size(625px, 70px);
    border-radius: 7px;
    background-color: $primary-color;
    color: $white;
    border: none;
  } 
}