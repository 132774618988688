@import '../../../styles/var';
@import '../../../styles/mixins';

.filterCont{
  background: #F6F7F9;
  padding: 24px 33px;
  width: calc( 100% + 60px );
  margin-left: -30px;
  margin-top: 45px;
  .filterIcon{
    display: none;
    @media (max-width: 1400px) {
      display: flex;
      cursor: pointer;
    }
  }
  .filterRightSideCont{
    flex-wrap: wrap;
    @media (max-width: 1400px) {
      display: none !important;
    }
    @media (max-width: 768px) {
      padding: 0px 15px;
    }
  }
}

.DashboardCardCont{
  justify-content: center;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3,calc((100% - 46px) / 3));
  grid-gap: 23px;
  @media (max-width: 1330px) {
    grid-template-columns: repeat(2,calc((100% - 23px) / 2));
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1,calc((100% - 23px) / 1));
  }
}

.noDataAvailableCont{
  width: 100%;
  padding: 100px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1300px) {
    & img{
      width: 300px;
    }
  }

  @media (max-width: 380px) {
    & img{
      width: 200px;
    }
  }
}