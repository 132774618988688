@import '../../../styles/var';
@import '../../../styles/mixins';
.profilePhotos{
  // position: relative;
  // .editCover{
  //   position: absolute;
  //   bottom: 30px;
  //   right: 30px;
  // }
  .myProfile{
    // color: #FEFEFE;
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
  }
  .myProfilePhoto{
    @include size(120px);
    @include imageSize(cover, center);
    border-radius: 50%;
    @media (max-width: 425px) {
      @include size(100px);
    }
  }
  .profilePhoto{
    @include size(120px);
    @include imageSize(contain, center);
    border-radius: 50%;
    padding: 30px;
    background: $primary-color;
    @media (max-width: 425px) {
      @include size(100px);
    }
  }
  // .nameImages{
  //   position: absolute;
  //   bottom: -110px;
  //   padding-right: 80px;
  // }
  .name{
    display: flex;
    align-items: end;
    margin-left: 50px;
  }

  .profilePhotoEdit{
    position: relative;
    height: 120px;
    margin-bottom: 12px;
    .editAvatar{
      position: absolute;
      bottom: 0px;
      right: -7px;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      padding: 8px;
      z-index: 1;
      background-color: $white;
      &:hover{
        cursor: pointer;
      }
      @media (max-width: 425px) {
        @include size(30px);
        right: -1px;
      }
    }
  
   
  }
}
.formsCont{
  // margin-top: 54px;
  .inputs{
    gap: 0px 50px;
    margin-top: 10px;
    label{
      width:46%;
      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }
}

.imgContainer {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  // margin-bottom: 22px;
  background: $primary-color;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.changePhotoCont{
  cursor: pointer;
  width: 39px;
  height: 39px;
  border-radius: 100%;
  background: $white;
  position: absolute;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0;
}