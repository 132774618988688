@import '../../../styles/var';
@import '../../../styles/mixins';
.header{
    display: flex;
    align-items: center;
    .logo{
        height: 51px;
        svg{
            height: 100%;
        }
    }
    .linkCont {
        display: flex;
        align-items: center;
        gap: 61px;
        padding: 39px 0px;
        .link {
            text-decoration: none;
            font-size: $fz-20;
            font-weight: $medium;
            font-family: Rubik-Medium;
        }
    }
    .orangeButton{
        @include size(166px, 52px);
        background: #FF8A00;
        color: $white;
        border-radius: 5px;
        border: none;
        font-size: $fz-18;
        font-weight: $medium;
        font-family: Rubik-Medium;
        margin-left: 129px;
    }
}

