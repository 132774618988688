@import '../../styles/var';
@import '../../styles/mixins';
.languageCont{
    position: relative;
    .languageContImg{
      position: absolute;
      left: 8px;
      
    }
    svg{
        display: none;
    }
  }
