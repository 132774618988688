@import "../../styles/var";
@import "../../styles/mixins";
.dashboardCard {
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(28, 55, 90, 0.16);
  background: #fff;
  border-right-width: 8px;
  text-decoration: none;
  .managerName{
    color: #939FA8;
    font-family: Inter-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
  }
  .dashboardCardTop {
    padding: 14px;
    width: 100%;
    min-height: 74px;
    flex-shrink: 0;
    border-radius: 4px 4px 0px 0px;
    background: #EEF3FF;
    display: flex;
    flex-direction: column;
    .taskType{
      color: #939FA8;
      font-family: Inter-Regular;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .taskName {
    color: $primary-color;
    font-family: Inter-Regular;
    font-size: $fz-20;
    font-style: normal;
    line-height: normal;
    font-weight: $medium;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .notificationCont {
    position: relative;
    svg {
      @include size(15px, 20px);
    }
    .round {
      @include size(8px);
      background: $error-color;
      border: 1px solid $white;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      right: 0px;
    }
  }
  .dashboardCardCont {
    padding: 15px;
    justify-content: center;
    svg {
      margin-right: 5px;
    }
    .managerSrc {
      @include size(16px);
      border-radius: 50%;
      background-color: $primary-color;
      img {
        @include size(12px);
        object-fit: contain;
      }
    }
    .dashboardCardMain{
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .companyName{
      color: $black;
      font-size: $fz-16;
      margin-bottom: 10px;
      font-family: Inter-Regular;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width: calc( 100% - 32px );
    }
    .createdAt {
      color: $gray-color;
      font-family: Inter-Regular;
      font-size: $fz-13;
      font-style: normal;
      font-weight: $regular;
      line-height: normal;
    }
  }
}
