@import '../../../styles/var';
@import '../../../styles/mixins';
.headingCont{
    position: relative;
    margin-top: 196px;
    @media (max-width: 1024px) {
        margin-top: 88px; 
    }
    .heading{
        font-size: 48px;
        font-weight: $semiBold;
        font-family: 'Rubik-SemiBold';
        color: $primary-color;
        text-align: center;
        @media (max-width: 1024px) {
            font-size: 32px;
        }
    }
    .headingNext{
        @include size(106px, 5px);
        background-color: $primary-color;
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 1024px) {
            top: 60px;
        }
    }
}
.sectionTitle{
    font-size: $fz-20;
    font-weight: $regular;
    font-family: 'Rubik-Regular';
    max-width: 797px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 61px;
}
.image{
    max-width: 1313px;
    height: auto;
    margin: auto;
    width:100% ;
    margin-top: 89px;
}