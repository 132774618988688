@import "../../styles/var";
@import "../../styles/mixins";

.employeeItem {
    background: var(--gray-050, #f7f8f9);
    cursor: pointer;
    text-decoration: none;
    border-radius: 6px;
    padding: 0px 0px 10px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    .messageIcon {
        position: absolute;
        top: 150px;
        right: 15px;

        svg {
            path {
                fill: $primary-color;
            }
        }
    }

    .imgContainer {
        border-radius: 50%;
        background: $primary-color;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        @include size(60px);
        position: absolute;
        bottom: -20px;
        left: 10px;

        img {
            width: 30px;
        }
    }

    .nameProfession {
        padding: 25px 10px 20px 10px;

        .name {
            color: #071440;
            font-family: Inter-SemiBold;
            font-weight: 600;
            font-size: $fz-20;
            font-style: normal;
            line-height: 24px;
            margin-bottom: 8px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: left;
        }

        .profession {
            color: #676972;
            font-family: Inter-Regular;
            font-size: $fz-18;
            font-style: normal;
            font-weight: $regular;
            line-height: 18px;
            text-align: left;
            display: flex;
            align-items: center;
            svg {
                path {
                    fill: $primary-color;
                }
                margin-right: 5px;
            }
        }
    }

    .imageCover {
        width: 100%;
        background-color: #97BCC7;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 80px;
    }

    .image {
        @include size(60px);
        position: absolute;
        bottom: -22px;
        left: 10px;
        // margin-bottom: 22px;
        border-radius: 100%;
        object-fit: cover;
        text-align: center;

        // @media (max-width: 1480px) {
        //   height: 275px;
        // }
        // @media (max-width: 425px) {
        //   height: 190px;
        // }
    }
}

.messageSvg {
    @include size(30px)
}