@import '../../styles/var';
@import '../../styles/mixins';

.notificationItem{
  margin-bottom: 30px;
  cursor: pointer;
  .color{
    @include size(9px);
    background:#14A9F9;
    border-radius: 50%;
  }
  .colorTransparent{
    background:transparent;
  }
  .titleNameDate{
    margin: 0 15px;
    flex: 1;
  }
  .title, .name{
    color: rgba(24, 39, 58, 0.94);
  font-family: Rubik-Medium;
  font-size: $fz-14;
  font-style: normal;
  font-weight: $medium;
  line-height: normal;
  letter-spacing: 0.28px;
  }
  .title{
    margin-bottom: 5px;
  }
  .name{

  }
  .date{
    color:rgba(28, 46, 69, 0.60);
    font-family: Rubik-Regular;
    font-size: $fz-14;
    font-style: normal;
    font-weight: $regular;
    line-height: 25px;
    letter-spacing: 0.28px;
  }
  .avatar{
    @include size(56px);
    @include imageSize('cover', 'center');
    border-radius: 50%;
  }
  .avatarCont{
    @include size(56px);
    border-radius: 50%;
    background: $primary-color;
    padding: 12px;
    display: flex;
    justify-content: center;
    img{
      @include size(100%);
      @include imageSize(contain, center);
    }
  }
}