@import '../../styles/var';
@import '../../styles/mixins';
.detailItem{
  width: 100%;
  .detailName{
    color: $gray-color;
    font-family: Rubik-Medium;
    font-size: $fz-16;
    font-style: normal;
    font-weight: $medium;
    line-height: 150%;
    margin-right: 10px;
  }
  .detailValue{
    color:  $black;
    text-align: right;
    font-style: normal;
    font-family: Rubik-SemiBold;
    font-size: $fz-16;
    font-weight: 600;
    line-height: 150%;
    // white-space: nowrap;
  }
}