@import '../../styles/var';
@import '../../styles/mixins';
.menuItem{
  margin-bottom: 0px;
  cursor: pointer;
  .iconTitle{
    padding: 19px 0;
    margin: 0 15px 0 24px;
    .title{
      margin-left: 20px;
      color: $gray-color;
      font-family: Rubik-Medium;
      font-size: $fz-16;
      font-style: normal;
      font-weight: $medium;
      line-height: normal;
      display: flex;
      flex: 1;
    }
    svg{
      path{
        fill: $gray-color;
      }
      @include size(20px)
    }
  }
}
.showOnlyIcon{
  .title{
    display: none !important;
  }
  .badge{
    display: none !important;
  }
}
.badge, .roundBadge{
  @include size(33px, 24px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  text-align: center;
  font-family: Rubik-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  background-color: $primary-color;
  border-radius: 5px;
}
.roundBadge{
  @include size(20px);
  border-radius: 50%;
}
.subMenu{
  padding: 0px 15px 0px 65px;
  color: #939FA8;
  font-family: Inter-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 5px;
  cursor: pointer;
}
.companyName{
  display: flex;
  flex: 1;
}
.subMenuActive{
  color: $primary-color;
}
.menuItemActive{
  position: relative;
  &::before{
    content: '';
    position: absolute;
    @include size(9px, 62px);
    background: $primary-color;
    border-radius: 0px 5px 5px 0px;
  }
  .title{
    color: $primary-color !important;
  }
  svg path{
    fill: $primary-color !important;
  }
}