@import '../../styles/var';
@import '../../styles/mixins';

.cont{
  // margin-bottom: 24px;
  @media (max-width: 425px) {
    width: 100%;
  }
  .hideText{
    color: $gray-color;
    cursor: pointer;
  }
  .title{
    color: $black;
    font-size: $fz-16;
    font-weight: $regular;
    font-family: Inter-Regular;
    margin-bottom: 4px;
    margin-right: 5px;
  }

  .input{
    @include size(100%, 45px);
    max-width: 501px;
    border-radius: 12px;
    border: 2px solid #97BCC7;
    padding-right: 22px;
    background: white;

    input{
      background: white;
      border: none;
      border-radius: 12px;
      height: 100%;
      outline: none;
      display: flex;
      flex: 1;
      font-size: $fz-12;
      font-family: Inter-Regular;
      font-weight: 400;
    }
    svg{
      cursor: pointer;      
    }
  }
  .focusedInput{
    border: 2px solid $primary-color !important;
  }
  .addLabel{
    color: $primary-color;
    font-family: Inter-Regular;
    font-size: $fz-16;
    font-style: normal;
    font-weight: $medium;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.07px;
  }
}