@import '../../../styles/var';
@import '../../../styles/mixins';

.pageHeading {
  color: $black;
  font-family: Rubik-Medium;
  font-size: $fz-42;
  font-style: normal;
  font-weight: $medium;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 1510px) {
    font-size: $fz-28;
  }

  @media (max-width: 1024px) {
    font-size: $fz-24;
  }
}

.sectionHeading {
  color: $primary-color;
  font-family: Inter-Regular;
  font-size: $fz-20;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 110% */
  letter-spacing: 0.1px;
  margin-bottom: 15px;
  margin-top: 15px;
  // margin-top: 30px;
  @media (max-width: 425px) {
    font-size: $fz-14;
  }
}
.cardContVacation{
  margin: 0 !important;
}
.cardContHeading {
  margin-top: 0;

  @media (max-width: 425px) {
    font-size: $fz-14;
  }
}

.cardUpload {

  .cardUploadItem {
    svg {
      margin-bottom: 10px;
      cursor: pointer;
    }

    .cardTitle {
      color: $gray-color;
      // text-align: center;
      font-family: Inter-Regular;
      font-size: $fz-12;
      font-style: normal;
      font-weight: $regular;
      line-height: normal;
    }
  }

  .cardLine {
    gap: 30px;
    margin-bottom: 30px;

    @media (max-width: 425px) {
      margin-bottom: 24px;
    }
  }

}

.headingButton {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  padding: 5px 0px;
  width: 100%;
  background-color: $white;
  margin-bottom: 53px;
  z-index: 1;

  // margin-top: 44px;
  @media (max-width: 425px) {
    margin-bottom: 24px;
  }
}

.inputCont {
  gap: 0px 60px;

  label {
    height: fit-content !important;
    width: 45%;

    @media (max-width: 1150px) {
      width: 100%;
    }
  }
}

.documentLabel {
  margin-top: 15px;
  margin-bottom: 24px;

  svg {
    cursor: pointer;
  }

  .label {
    margin-left: 27px;
    color: $primary-color;
    text-align: center;
    font-family: Inter-Regular;
    font-size: $fz-12;
    font-style: normal;
    font-weight: $regular;
    line-height: 161.104%;
  }
}

.addProduct {
  display: flex;
  align-items: center;
  color: var(--Body-Text, rgba(25, 39, 57, 0.94));
  font-family: Inter-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 161.104%;
  margin-bottom: 15px;
  cursor: pointer;
  span {
    margin-right: 5px;
  }
}