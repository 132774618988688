@import '../../styles/var';
@import '../../styles/mixins';

.button{
  @include size(239px, 48px);
  border-radius: 5px;
  background: $button-color;
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: $fz-14;
  // @media (max-width: 425px) {
  //   width: 100%;
  // }
}