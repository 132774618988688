@import '../../styles/var';
@import '../../styles/mixins';
.customRadio{
  margin-bottom: 24px;
  .label{
    color: $primary-color;
    font-family: Inter-Regular;
    font-size: $fz-20;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 110% */
    letter-spacing: 0.1px;
    @media (max-width: 425px) {
      font-size: $fz-14;
    }
  }
  .customRadioItem{
    margin-right: 24px;
    margin-top: 18px;
    @media (max-width: 425px) {
      margin-top: 8px;
    }
    .customRadioItemLabel{
      color: var(--Body-Text, rgba(25, 39, 57, 0.94));
      font-family: Inter-Regular;
      font-size: $fz-16;
      font-style: normal;
      font-weight: $regular;
      line-height: 161.104%;
      margin-left: 5px;
      margin-top: -3px;
    }
  }
}