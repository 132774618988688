@import '../../styles/var';
@import '../../styles/mixins';
.label{
  color: $black;
  font-family: Inter-Regular;
  font-size: $fz-16;
  // font-style: normal;
  font-weight: $regular;
  // line-height: normal;
  margin-bottom: 4px;
}