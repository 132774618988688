@import "../../styles/var";
@import "../../styles/mixins";

.companyItem {
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  text-align: center;
  padding: 18px;
  align-items: center;
  background-color: #f7f8f9;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 215px;
  height: 100%;
  position: relative;
  @media screen and (max-width: 480px) {
    min-height: 162px;
    height: 100%;
    padding: 16px;
  }
  .deactivated{
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 4px;
    background-color: $text-color;
    position: absolute;
    top: 5px;
    left: 5px;
    padding: 0px 5px;
  }
  .tarif{
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $fz-14;
    color: $white;
    border-radius: 4px;
    background-color: #44C7E3;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .premiumTarif{
    background-color: #FF4572;
  }
  .nameProfession {
    width: 100%;

    .name {
      color: #2a3342;
      text-align: center;
      font-family: Rubik-SemiBold;
      font-size: $fz-24;
      font-style: normal;
      line-height: 30px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 600;
      margin-top: 22px;
      margin-bottom: 8px;

      @media screen and (max-width: 480px) {
        color: $button-color;
        font-size: $fz-14;
        font-weight: $medium;
        line-height: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        margin: 8px 0;
      }
    }
    .managers {
      color: $gray-color;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter-Regular;
      font-size: $fz-18;
      font-style: normal;
      font-weight: $regular;
      line-height: 20px;
      margin-top: 10px;
    }
  }

  .image {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    object-fit: cover;

    @media screen and (max-width: 480px) {
      width: 80px;
      height: 80px;
    }
  }

  .managerAvatar {
    @include size(35px);
    border-radius: 100%;
    object-fit: cover;

    @media screen and (max-width: 480px){
      @include size(25px);
    }
  }

  .managersCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 2px solid $primary-color;
    }
  }

  .oneManagerCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 8px;
      @include size(35px);
      object-fit: cover;

      @media screen and (max-width: 480px){
        @include size(25px);
        margin-right: 10px;
      }
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .managersCount {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 2px solid $white;
    left: 65px;
    z-index: 100;
    background-color: rgb(214, 210, 210);
    font-size: 9px;
    color: rgba(27, 43, 65, 0.69);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .managerName {
    color: $primary-color;
    text-align: center;
    font-family: Rubik-Medium;
    font-size: $fz-18;
    font-style: normal;
    font-weight: $medium;
    line-height: 28px;
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media screen and (max-width: 480px){
      font-size: $fz-14;
      font-weight: $regular;
      line-height: 20px;
      -webkit-line-clamp: 1;
    }
  }

  .imgContainer {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: $primary-color;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $primary-color;
  }
  .managerDefault {
    @include size(35px);
    border-radius: 50%;
    background: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    object-fit: cover;
    img {
      @include size(25px);
      margin-right: 0px;
      object-fit: contain;
    }
  }
}
.accountantWorkerImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.accWorkerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountantWorkerImgCont {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(0, 104, 132, 1);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 15px;
    height: 15px;
  }
}

.workerName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: $primary-color;
  text-align: center;
  font-family: Rubik-Medium;
  font-weight: $medium;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
}
